import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import SpaceCompleteContainer from "/src/containers/space-complete";
import { useDispatch } from "react-redux";

// // componen
import config from "/src/config.js";
import api from "/src/api";
import { setCurrentAsset } from "../../redux/actions/misc-actions";
import { transformSpace } from "../../redux/reducers/space-reducer";
import SEO from "../../components/seo";

export default function Component(props) {
  const dispatch = useDispatch();
  const [space, setSpace] = useState(transformSpace({ ...props.data.space, id: props.data.space.space_id }));

  useEffect(() => {
    //dispatch current asset to set proper styles
    dispatch(setCurrentAsset(space.asset));
  }, []);

  useEffect(async () => {
    if (typeof window !== "undefined") {
      // we know the format
      const pathname = window.location.pathname;
      const spaceName = pathname.substr(pathname.indexOf("/space/") + "/space/".length);
      const spaceId = spaceName?.substr(0, spaceName.indexOf("-"));
      const response = await api.getSpace(spaceId);
      //dispatch(setCurrentAsset(response.data.payload.asset));
      if (response.status === 200 && response.data) {
        setSpace(transformSpace(response.data.payload));
      }
    }
  }, []);

  return (
    <>
      <SEO
        title={`${config.CLIENT_NAME} - ${space.title}`}
        description={space.description}
        image={space.images.length ? encodeURI(space.images[0].url) : ""}
      />
      <BacThemeProvider assetId={space.asset.id}>
        <Navbar />
        <SpaceCompleteContainer space={space} />
      </BacThemeProvider>
    </>
  );
}
// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query ($id: String) {
    space(id: { eq: $id }) {
      id
      asset_id
      asset_id_gql
      description
      capacity
      attachments {
        description
        id
        role
        type
        url
        space_id
      }
      display_price
      features {
        id
        name
      }
      full_month_price
      full_week_price
      full_weekend_price
      latitude
      level
      longitude
      min_stay
      max_stay
      searchable
      size
      space_id
      space_sub_type_id
      space_type_id
      week_day_price
      type {
        name
        id
      }
      subtype {
        id
        name
      }
      title
      asset {
        id
        name
        description
        logo_dark_url
        logo_light_url
        city {
          name
        }
        config {
          show_space_price
        }
      }
    }
  }
`;
